import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import DatePicker from '../../forecast-app/shared/components/date-picker/date_picker_v3';
import Dropdown from '../../forecast-app/shared/components/dropdowns/dropdown';
import Util from '../../forecast-app/shared/util/util';
import UpdateTaskMutation from '../../mutations/update_task_mutation.modern';
import {createToast} from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import UploadingOverlay from '../../forecast-app/shared/components/uploading-overlay/uploading_overlay';
import {BUTTON_STYLE, BUTTON_COLOR, DATE_PICKER_STYLE, ESTIMATION_UNIT} from '../../constants';
import GenericModal from '../../containers/modal/generic_modal';
import AssignedDropdown from '../../forecast-app/shared/components/dropdowns/assigned-dropdown/assigned_dropdown';
import CheckBox from '../../components/new-ui/check_box';
import Styled from 'styled-components';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import HoursInput from '../../forecast-app/shared/components/inputs/hours-input/hours_input_view';
import Button from '../../forecast-app/shared/components/buttons/button/button';
import {AffixedInputWrapper} from '../../forecast-app/shared/components/inputs/AffixedInputWrapper';
import {hasPermission} from '../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../Permissions';
import * as tracking from '../../tracking';
import {LabelDropdown} from '../../components/dropdowns/label-dropdown/Label_dropdown';
import {trackEvent} from '../../tracking/amplitude/TrackingV2';
import {isDateDisabled} from '../../forecast-app/shared/util/DateUtil';
import TooltipContainer from '../../forecast-app/shared/components/tooltips/tooltip_container';
class BulkTaskUpdateModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			forecast_est: '',
			start_date: '',
			end_date: '',
			role: '',
			approve: '',
			status: '',
			assigned: '',
			followers: '',
			labels: [],
			clearLabels: false,
			saving: false,
			isClientViewRestricted: Util.isClientTaskViewRestricted(props.viewer),
		};
	}

	componentDidMount() {
		tracking.trackPageAction('Bulk task update modal shown');
		trackEvent('Bulk Task Update Modal', 'Shown');
	}

	validate() {
		let estForecast = this.state.forecast_est && this.state.forecast_est !== '' ? this.state.forecast_est : 0;
		//Round to nearest 0.05 if hours to make sure it produces integer when multiplied by 60, round to two decimals if points
		if (this.state.forecast_est !== '') {
			this.setState({forecast_est: estForecast});
		}
	}

	changeEstimateForecast(value) {
		this.setState(
			{
				forecast_est: value,
			},
			() => this.validate()
		);
	}

	/**
	 * Sets estimates to null or empty string depending on the previous value of the estimates
	 * @returns {void}
	 */
	clearEstimates() {
		let newEstimatesState = null;
		if (this.state.forecast_est !== null) {
			newEstimatesState = null;
		} else {
			newEstimatesState = '';
		}

		this.setState({
			forecast_est: newEstimatesState,
		});
	}

	handleDateRangeChange(startDate, endDate) {
		this.setState({
			start_date: startDate,
			end_date: endDate,
		});
	}

	clearDates() {
		let newDatesState = null;
		if (this.state.start_date !== null || this.state.end_date !== null) {
			newDatesState = null;
		} else {
			newDatesState = '';
		}
		this.setState({
			start_date: newDatesState,
			end_date: newDatesState,
		});
	}

	DatePickerClearDates() {
		this.clearDates();
	}

	changeRole(selected) {
		this.setState({
			role: selected ? selected.value : null,
		});
	}

	clearRoles() {
		let newRole = null;
		if (this.state.role === null) {
			newRole = '';
		}
		this.setState({
			role: newRole,
		});
	}

	changeStatus(selected) {
		this.setState({status: selected.value});
	}

	getLabels() {
		const selectedIds = this.state.labels;
		const labels = this.props.labels;
		return labels.filter(edge => selectedIds.includes(edge.node.id)).map(edge => edge.node);
	}

	addLabel(labelId) {
		this.setState({
			labels: this.state.labels.concat([labelId]),
			clearLabels: false,
		});
	}

	removeLabel(labelIdToRemove) {
		this.setState({
			labels: this.state.labels.filter(stateLabel => stateLabel !== labelIdToRemove),
		});
	}

	clearLabels() {
		this.setState({
			labels: [],
			clearLabels: !this.state.clearLabels,
		});
	}

	changeApprove(selected) {
		this.setState({approve: selected.value});
	}

	closeModal = () => {
		this.props.closeModal();
	};
	saveUpdate() {
		const onSuccess = result => {
			this.setState({saving: false});
			if (!result.updateTask.errors) {
				createToast({duration: 5000, message: this.props.intl.formatMessage({id: 'bulk_update.toast'})});
			}
			this.props.closeModal();
			if (this.props.saveCallback) {
				this.props.saveCallback(result.updateTask.errors, result);
			}
		};

		this.setState({saving: true});

		let mutationObject = {
			ids: this.props.tasks,
			isBulkContext: true,
		};
		if (this.state.forecast_est !== '') {
			const forecastEst =
				this.props.estimationUnit === 'HOURS' ? this.state.forecast_est * 60.0 : this.state.forecast_est;
			mutationObject.forecast = forecastEst;
		}
		if (this.state.start_date !== '') {
			const start = Util.GetYearMonthDateFromMomentDate(this.state.start_date);
			mutationObject.startYear = start.year;
			mutationObject.startMonth = start.month;
			mutationObject.startDay = start.day;
		}
		if (this.state.end_date !== '') {
			const end = Util.GetYearMonthDateFromMomentDate(this.state.end_date);
			mutationObject.deadlineYear = end.year;
			mutationObject.deadlineMonth = end.month;
			mutationObject.deadlineDay = end.day;
		}
		if (this.state.role !== '') {
			mutationObject.roleId = this.state.role;
		}
		if (this.state.assigned !== '') {
			if (this.state.assigned.length === 0) {
				mutationObject.unassignAllPersons = true;
			}
			mutationObject.assignedPersons = this.state.assigned.map(el => el.id);
		}
		if (this.state.followers !== '') {
			if (this.state.followers.length === 0) {
				mutationObject.removeAllFollowers = true;
			}
			mutationObject.followers = this.state.followers.map(el => el.id);
		}
		if (this.state.status !== '') {
			if (this.props.projectGroupId) {
				mutationObject.projectGroupStatusColumnId = this.state.status;
			} else {
				mutationObject.statusColumnId = this.state.status;
			}
		}
		if (this.state.approve !== '') {
			mutationObject.approved = this.state.approve;
		}

		if (this.state.labels && this.state.labels.length > 0) {
			mutationObject.labels = this.state.labels;
		}
		if (this.state.clearLabels) {
			mutationObject.clearAllLabels = true;
		}

		const changes = [];

		for (const key of Object.keys(mutationObject)) {
			if (
				[
					'ids',
					'id',
					'aboveTaskOrder',
					'viewerId',
					'viewerActualId',
					'optimisticTaskOrder',
					'previousAssignedPersons',
				].includes(key) ||
				mutationObject[key] === undefined
			) {
				continue;
			}

			changes.push(key);
		}

		Util.CommitMutation(UpdateTaskMutation, mutationObject, onSuccess);
	}

	assignPerson(personIds) {
		this.setState({
			assigned: personIds.map(
				personId =>
					this.getPeopleOptions().find(projectPerson => {
						return projectPerson.node.person.id === personId;
					}).node.person
			),
		});
	}

	clearAssigned() {
		let newAssignedState = null;
		if (this.state.assigned && this.state.assigned.length > 0) {
			newAssignedState = [];
		} else if (this.state.assigned) {
			newAssignedState = '';
		} else {
			newAssignedState = [];
		}
		this.setState({
			assigned: newAssignedState,
		});
	}

	unassignPerson(personId) {
		const assigned = this.state.assigned;
		let filteredAssigned = assigned.filter(personEdge => personEdge.id !== personId);
		this.setState({assigned: filteredAssigned.length <= 0 ? '' : filteredAssigned});
	}

	assingFollower(personIds) {
		this.setState({
			followers: personIds.map(
				personId =>
					this.getPeopleOptions().find(projectPerson => {
						return projectPerson.node.person.id === personId;
					}).node.person
			),
		});
	}

	clearFollowers() {
		let newFollowerState = null;
		if (this.state.followers && this.state.followers.length > 0) {
			newFollowerState = [];
		} else if (this.state.followers) {
			newFollowerState = '';
		} else {
			newFollowerState = [];
		}
		this.setState({
			followers: newFollowerState,
		});
	}

	unassignFollower(personId) {
		const followers = this.state.followers;
		let filteredFollowers = followers.filter(personEdge => personEdge.id !== personId);
		this.setState({followers: filteredFollowers.length <= 0 ? '' : filteredFollowers});
	}

	getPeopleOptions() {
		return this.props.people_options.map(person => {
			return {
				node: {
					person: {
						id: person.value,
						firstName: person.label,
						profilePictureDefaultId: person.profilePictureDefaultId,
						profilePictureId: person.profilePictureId,
						active: true,
					},
					role: null,
				},
			};
		});
	}

	render() {
		const hasPhaseUpdatePermission = hasPermission(PERMISSION_TYPE.PHASE_UPDATE);

		const {formatMessage} = this.props.intl;
		const buttons = [
			{
				text: formatMessage({id: 'common.cancel'}),
				style: BUTTON_STYLE.FILLED,
				color: BUTTON_COLOR.WHITE,
				callback: this.closeModal.bind(this),
				cy: 'bulk-update-modal-cancel',
			},
			{
				text: formatMessage({id: 'common.update'}),
				style: BUTTON_STYLE.FILLED,
				color: BUTTON_COLOR.GREEN,
				callback: this.saveUpdate.bind(this),
				cy: 'bulk-update-modal-update',
			},
		];

		const buttonContainer = (
			<div className="button-container">
				{buttons.map((button, index) => (
					<Button
						id={button.id}
						data-cy={button.cy}
						isDisabled={button.disabled}
						key={`generic-modal-button-${index}`}
						text={button.text}
						cy={button.cy}
						buttonStyle={button.style || BUTTON_STYLE.FILLED}
						colorTheme={button.color || BUTTON_COLOR.GREEN}
						onClick={button.callback}
						autoScheduleStyle={button.autoScheduleStyle}
						className={button.className}
					/>
				))}
			</div>
		);

		const content = (
			<>
				{this.state.saving ? <UploadingOverlay /> : null}
				<div className="bulk-task-modal-content">
					<CustomScrollDiv autoHeight={true} autoHeightMin={1} autoHeightMax={720}>
						{!this.props.isJiraProject ? (
							<div className="sub-section">
								<div className="inputs-wrapper">
									<InputGroup>
										<Label>{formatMessage({id: 'common.estimate'})}</Label>
										<Inputs>
											{hasPermission(PERMISSION_TYPE.CLIENT_HIDE_ESTIMATES) ? (
												<HoursInput
													value={'-'}
													customClassName={'hours-input'}
													onClick={e => e.focus()}
													disabled={true}
												/>
											) : this.props.estimationUnit === ESTIMATION_UNIT.HOURS ? (
												<HoursInput
													customClassName={'hours-input'}
													value={this.state.forecast_est}
													mutationChangeOnly
													mutation={v => this.changeEstimateForecast(v)}
													onClick={e => e.focus()}
													cy={'bulk-estimate-input'}
												/>
											) : (
												<AffixedInputWrapper
													onClick={e => e.focus()}
													value={this.state.forecast_est}
													callback={value => this.changeEstimateForecast(value)}
													affix={formatMessage({id: 'common.points.short'})}
												/>
											)}
										</Inputs>
									</InputGroup>
								</div>
								<CheckboxAndText>
									<CheckBox
										cy="bulk-update-modal-remove-estimate"
										isDisabled={hasPermission(PERMISSION_TYPE.CLIENT_HIDE_ESTIMATES)}
										isChecked={this.state.forecast_est === null}
										onClick={this.clearEstimates.bind(this)}
									/>
									<div className="text">
										{this.props.intl.formatMessage({id: 'bulk_edit.clear_estimates'})}
									</div>
								</CheckboxAndText>
							</div>
						) : null}
						<div className="sub-section">
							<InputGroup>
								<Label>{formatMessage({id: 'common.dates'})}</Label>
								<Inputs>
									<div className="date-picker-wrapper" data-cy="bulk-edit-modal-date-picker">
										<DatePicker
											buttonCy="date-button"
											startDate={this.state.start_date !== '' ? this.state.start_date : null}
											endDate={this.state.end_date !== '' ? this.state.end_date : null}
											handleDateRangeChange={this.handleDateRangeChange.bind(this)}
											clearable={true}
											clearBothDates={this.DatePickerClearDates.bind(this)}
											datePickerStyle={DATE_PICKER_STYLE.STANDARD}
											startDateLabel={formatMessage({id: 'common.start_date'})}
											endDateLabel={formatMessage({id: 'common.end_date'})}
											disabledDates={isDateDisabled}
										/>
									</div>
								</Inputs>
							</InputGroup>
							<TooltipContainer infoText={this.props.intl.formatMessage({id: 'bulk_edit.clear_dates_tooltip'})}>
								<CheckboxAndText>
									<CheckBox
										cy="bulk-update-modal-remove-dates"
										isChecked={this.state.start_date === null && this.state.end_date === null}
										onClick={this.clearDates.bind(this)}
									/>
									<div className="text">{this.props.intl.formatMessage({id: 'bulk_edit.clear_dates'})}</div>
								</CheckboxAndText>
							</TooltipContainer>
						</div>

						<div className="sub-section">
							<InputGroup>
								<Label>{formatMessage({id: 'bulk_card_update_modal.add_labels'})}</Label>
								<Inputs>
									<LabelDropdown
										labels={this.props.labels.map(edge => edge.node)}
										onSelect={this.addLabel.bind(this)}
										onRemove={this.removeLabel.bind(this)}
										selectedItems={this.state.labels}
										selectedGroupName={formatMessage({id: 'common.added'})}
										name={formatMessage({id: 'settings_labels.add-label'})}
										showLabelsBelow
										usePortal
									/>
								</Inputs>
							</InputGroup>
							<CheckboxAndText>
								<CheckBox isChecked={this.state.clearLabels} onClick={this.clearLabels.bind(this)} />
								<div className="text">{this.props.intl.formatMessage({id: 'bulk_edit.clear_labels'})}</div>
							</CheckboxAndText>
						</div>
						<div className="sub-section">
							<InputGroup>
								<Label>{formatMessage({id: 'common.assign_role'})}</Label>
								<Inputs>
									<Dropdown
										placeholder={formatMessage({id: 'common.assign_role'})}
										multi={false}
										hideLabel={true}
										clearable={true}
										customHeight={30}
										options={this.props.role_options}
										onChange={this.changeRole.bind(this)}
										value={this.state.role !== '' ? this.state.role : ''}
										disabled={this.state.isClientViewRestricted}
									/>
								</Inputs>
							</InputGroup>
							<CheckboxAndText>
								<CheckBox
									isDisabled={this.state.isClientViewRestricted}
									isChecked={this.state.role === null}
									onClick={this.clearRoles.bind(this)}
								/>
								<div className="text">{this.props.intl.formatMessage({id: 'bulk_edit.unassign_roles'})}</div>
							</CheckboxAndText>
						</div>
						<div
							className={
								!this.props.useTaskFollowers && !hasPhaseUpdatePermission ? 'sub-section last' : 'sub-section'
							}
						>
							<InputGroup>
								<Label>{formatMessage({id: 'common.assign_people'})}</Label>
								<Inputs>
									<AssignedDropdown
										customHeight={30}
										assignablePersons={this.getPeopleOptions()}
										assignedPersons={this.state.assigned || []}
										assignPerson={this.assignPerson.bind(this)}
										unassignPerson={this.unassignPerson.bind(this)}
										hideLabel={true}
										maxPeopleIconsShown={6}
										disabled={this.props.isClientUser}
										isMultiSelect={true}
										showSuggestions={true}
									/>
								</Inputs>
							</InputGroup>
							<CheckboxAndText>
								<CheckBox
									isDisabled={this.state.isClientViewRestricted}
									isChecked={!!this.state.assigned && this.state.assigned.length === 0}
									onClick={this.clearAssigned.bind(this)}
								/>
								<div className="text">{this.props.intl.formatMessage({id: 'bulk_edit.unassign_people'})}</div>
							</CheckboxAndText>
						</div>
						{this.props.useTaskFollowers && (
							<div className={!hasPhaseUpdatePermission ? 'sub-section last' : 'sub-section'}>
								<InputGroup>
									<Label>{formatMessage({id: 'common.followers'})}</Label>
									<Inputs>
										<AssignedDropdown
											customPlaceholder={formatMessage({id: 'bulk_edit.assign_followers'})}
											customHeight={30}
											assignablePersons={this.getPeopleOptions()}
											assignedPersons={this.state.followers || []}
											assignPerson={this.assingFollower.bind(this)}
											unassignPerson={this.unassignFollower.bind(this)}
											hideLabel={true}
											maxPeopleIconsShown={6}
											disabled={this.props.isClientUser}
											isMultiSelect={true}
											showSuggestions={true}
										/>
									</Inputs>
								</InputGroup>
								<CheckboxAndText>
									<CheckBox
										isChecked={!!this.state.followers && this.state.followers.length === 0}
										onClick={this.clearFollowers.bind(this)}
									/>
									<div className="text">
										{this.props.intl.formatMessage({id: 'bulk_edit.unassign_followers'})}
									</div>
								</CheckboxAndText>
							</div>
						)}
						{!this.props.isClientUser && hasPhaseUpdatePermission && (
							<div className="sub-section last">
								<InputGroup>
									<Label>{formatMessage({id: 'bulk_edit.approve_tasks'})}</Label>
									<Inputs>
										<Dropdown
											customHeight={30}
											placeholder={formatMessage({id: 'bulk_edit.select_option'})}
											hideLabel={true}
											multi={false}
											options={[
												{value: false, label: formatMessage({id: 'common.no'})},
												{value: true, label: formatMessage({id: 'common.yes'})},
											]}
											onChange={this.changeApprove.bind(this)}
											value={this.state.approve !== '' ? this.state.approve : ''}
											alwaysShowOnTop={true}
										/>
									</Inputs>
								</InputGroup>
							</div>
						)}
						<ButtonContainerPlacement>{buttonContainer}</ButtonContainerPlacement>
					</CustomScrollDiv>
				</div>
			</>
		);
		return (
			<GenericModal
				closeModal={this.props.closeModal}
				content={content}
				headerText={formatMessage({id: 'bulk_card_update_modal.title'})}
			/>
		);
	}
}
export default injectIntl(BulkTaskUpdateModal);
const ButtonContainerPlacement = Styled.div`
	margin-right: 16px;
`;
const CheckboxAndText = Styled.div`
	margin-top: 16px;
	display: flex;
	align-items: center;
	> div.text {
		font-size: 13px;
  		color: #535353;
		margin-left: 10px;
	}
`;

const InputGroup = Styled.div`
	width: auto;
	display: flex;
	flex-direction: column;

	.clear-input {
		bottom:0px !important;
	}
`;

const Inputs = Styled.div`
	display: flex;
	flex-direction: row;
	margin-right: 8px;
	flex-wrap: wrap;
	div (
		flex-grow: 1;
	)
`;

const Label = Styled.label`
  font-size: 9px;
  letter-spacing: 1px;
  color: #a1a1a1;
  margin-bottom: 5px;
  font-weight: 500;
  text-transform: uppercase;

`;
