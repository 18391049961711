import {injectIntl} from 'react-intl';
import GenericModal from './generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import React, {useState} from 'react';
import Styled from 'styled-components';
import moment from 'moment';
import UpdateFixedPriceLockMutation from '../../mutations/update_fixed_price_lock_mutation';
import Util from '../../forecast-app/shared/util/util';
import {calculateOptions, getLockedPeriodByDate} from '../../components/new-ui/project/project-budget-v3/util/PeriodLocks';
import {Dropdown} from 'web-components';

const ContentWrapper = Styled.div`
	display: flex;
	width: 400px;
	align-items: baseline;
	height: 100px;
	font-size: 13px;
`;

const DropdownWrapper = Styled.div`
	width: 150px;
	margin-right: 16px;
`;

const DropdownLabel = Styled.div`
	margin-right: 16px;
`;

const fixedPriceOpenLockModal = ({closeModal, project, month, intl: {formatMessage}, callback}) => {
	const lockedPeriods = project.fixedPriceLocks.edges.map(lockedPeriod => lockedPeriod.node);
	const options = calculateOptions(project, false);
	const preSelectedMonth = options.find(option => moment(option.value).format('YYYY-MM') === month);

	const [selectedOption, setSelectedOption] = useState(preSelectedMonth ? preSelectedMonth.value : options[0]?.value ?? null);
	const [saving, setSaving] = useState(false);

	const updateFixedPriceLock = lockId => {
		Util.CommitMutation(UpdateFixedPriceLockMutation, {id: lockId, locked: false});
	};

	const onSave = () => {
		setSaving(true);
		if (selectedOption) {
			const lockedPeriod = getLockedPeriodByDate(moment(selectedOption), lockedPeriods);
			updateFixedPriceLock(lockedPeriod.id);
		}
		callback();
	};

	const onPeriodSelect = value => {
		setSelectedOption(value);
	};

	const content = (
		<ContentWrapper>
			<DropdownLabel>{formatMessage({id: 'fixed_price_lock.unlock_message'})}</DropdownLabel>
			<DropdownWrapper>
				<Dropdown
					usePortal={true}
					disabled={saving}
					name={''}
					selectedItems={[selectedOption]}
					onSelect={value => onPeriodSelect(value[0])}
				>
					{options.map(option => (
						<Dropdown.SingleText key={option.value} value={option.value} searchString={option.label}>
							{option.label}
						</Dropdown.SingleText>
					))}
				</Dropdown>
			</DropdownWrapper>
			?
		</ContentWrapper>
	);

	return (
		<GenericModal
			className="fixed-price-lock-modal-budget"
			closeModal={closeModal}
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.confirm'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: () => onSave(),
				},
			]}
			headerText={formatMessage({id: 'fixed_price_lock.unlock_modal_button'})}
			content={content}
		/>
	);
};

export default injectIntl(fixedPriceOpenLockModal);
